.timeline {
    list-style:none;
    padding:20px 0 20px;
    position:relative;
    > li {
        margin-bottom:20px;
        position:relative;
        > .timeline-panel {
            width:46%;
            float:left;
            border:1px solid #d4d4d4;
            border-radius:2px;
            padding:20px;
            position:relative;
            -webkit-box-shadow:0 1px 6px rgba(0,0,0,0.175);
            box-shadow:0 1px 6px rgba(0,0,0,0.175);
        }
        > .timeline-panel:before {
            position:absolute;
            top:26px;
            right:-15px;
            display:inline-block;
            border-top:15px solid transparent;
            border-left:15px solid #cccccc;
            border-right:0 solid #cccccc;
            border-bottom:15px solid transparent;
            content:" ";
        }
        > .timeline-panel:after {
            position:absolute;
            top:27px;
            right:-14px;
            display:inline-block;
            border-top:14px solid transparent;
            border-left:14px solid #ffffff;
            border-right:0 solid #ffffff;
            border-bottom:14px solid transparent;
            content:" ";
        }
        > .timeline-badge {
            color:#ffffff;
            width:50px;
            height:50px;
            line-height:50px;
            font-size:1.4em;
            text-align:center;
            position:absolute;
            top:16px;
            left:50%;
            margin-left:-25px;
            background-color:#999999;
            z-index:100;
            border-top-right-radius:50%;
            border-top-left-radius:50%;
            border-bottom-right-radius:50%;
            border-bottom-left-radius:50%;
        }
    }
    > li:before {
        content:" ";
        content:" ";
        display:table;
        display:table;
    }
    > li:after {
        content:" ";
        content:" ";
        display:table;
        display:table;
        clear:both;
        clear:both;
    }
    > li.timeline-inverted > .timeline-panel {
        float:right;
    }
    > li.timeline-inverted > .timeline-panel:before {
        border-left-width:0;
        border-right-width:15px;
        left:-15px;
        right:auto;
    }
    > li.timeline-inverted > .timeline-panel:after {
        border-left-width:0;
        border-right-width:14px;
        left:-14px;
        right:auto;
    }
}
.timeline:before {
    top:0;
    bottom:0;
    position:absolute;
    content:" ";
    width:3px;
    background-color:#eeeeee;
    left:50%;
    margin-left:-1.5px;
}
.timeline-badge.primary {
    background-color:#2e6da4 !important;
}
.timeline-badge.success {
    background-color:#3f903f !important;
}
.timeline-badge.warning {
    background-color:#f0ad4e !important;
}
.timeline-badge.danger {
    background-color:#d9534f !important;
}
.timeline-badge.info {
    background-color:#5bc0de !important;
}
.timeline-title {
    margin-top:0;
    color:inherit;
}
.timeline-body {
    > p, > ul {
        margin-bottom:0;
    }
    > p + p {
        margin-top:5px;
    }
}
