@import "../../../bower_components/bootstrap/less/glyphicons";
@import "../../../bower_components/font-awesome/less/font-awesome";
@import "../../../bower_components/bootstrap/less/bootstrap";
@import './timeline.less';

//
// General
// --------------------------------------------------
a, button {
    &.has-glyphicon:hover  {
        text-decoration: none;
    }
}

.btn:focus,.btn:active:focus,.btn.active:focus {
    outline: none;
}

.alert.alert-with-button {
    span {
        display: inline-block;
        line-height: 1.42857143;
    }

    line-height: 2.3;
}

div[itemtype="http://schema.org/PostalAddress"] {

    span[itemprop="streetAddress"], span[itemprop="addressCountry"] {
        display: block;
    }

    &.address-lines-1 {
        span {
            display: inline-block;
        }

        span[itemprop="streetAddress"]:after {
            content: ", ";
        }
    }

    &.address-lines-2 {
        span[itemprop="addressCountry"] {
            display: inline-block;
        }
    }

    &.address-lines-1, &.address-lines-2 {
        span[itemprop="postalCode"]:after {
            content: ", ";
        }
    }

    span[itemprop="addressLocality"]:after {
        content: ", ";
    }

    span:last-child:after {
        display: none;
    }
}

div[itemtype="http://schema.org/Person"] {
    span[itemprop="name"] {
        display: block;
        font-weight: bold;
    }

    div[itemprop="address"] {
        margin: 5px 0;
    }
}

//
// Menu
// --------------------------------------------------
nav {
    a.navbar-brand {
        img {
            max-height: 30px;
            margin-top: -5px;
            display: inline-block;
        }
    }

    img.gravatar {
        border-radius: 50%;
        margin-right: 3px;
    }
}

//
// Error pages
// --------------------------------------------------
.error-message.jumbotron {
    margin-top: 100px;
    background: #fff;
    border: 1px solid #eeeeee;
}

//
// Bootstrap Datepicker overridden settings
// --------------------------------------------------
.datepicker table tr td.today, .datepicker table tr td.today:hover, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover {
    background-color: #eee;
    border-color: #ccc;
}

.datepicker table tr td.today:hover, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today:focus, .datepicker table tr td.today:hover:focus, .datepicker table tr td.today.disabled:focus, .datepicker table tr td.today.disabled:hover:focus, .datepicker table tr td.today:active, .datepicker table tr td.today:hover:active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:hover.active, .open .dropdown-toggle.datepicker table tr td.today, .open .dropdown-toggle.datepicker table tr td.today:hover, .open .dropdown-toggle.datepicker table tr td.today.disabled, .open .dropdown-toggle.datepicker table tr td.today.disabled:hover {
    background-color: #ccc;
    border-color: #aaa;
}

//
// File Uploads
// --------------------------------------------------
.fileinput-button {
    position: relative;
    overflow: hidden;

    input {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        -ms-filter: 'alpha(opacity=0)';
        font-size: 200px;
        direction: ltr;
        cursor: pointer;
    }
}

//
// Contact us page
// --------------------------------------------------
.contact-page dl dd {
    margin-bottom: 1em;
    white-space: pre;
}

//
// My Account pages
// --------------------------------------------------
.my-account-index-page {
    .panel {
        .gravatar {
            margin-top: 22px;
        }

        .btn-toolbar {
            padding-bottom: 30px;
        }
    }

    dl dd {
        margin-bottom: 1em;
    }
}

//
//  Common between New Application & Permit View
// --------------------------------------------------
.permits-create-page, .permits-show-page, .permits-reviews-show-page {
    #attachments {
        .files-list {

            margin: 2em 0 0 0;

            h5 {
                margin-bottom: 1.428em;
            }

            &.empty {
                display: none;
            }

            .file {
                a.title {
                    display: inline-block;
                    max-width: 300px;
                    .text-overflow();
                }

                .links {
                    margin-top: 5px;

                    a {
                        margin-right: 2em;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    form {
                        display: inline-block;
                    }
                }

                .file-icon {
                    .fa;

                    font-size: 64px;
                    padding: 0 10px 0 20px;

                    &:before {
                        content: @fa-var-file-o;
                    }
                }

                // Specific icons for the different file types
                &.file-archive { .file-icon:before { content: @fa-var-file-archive-o; } }
                &.file-pdf     { .file-icon:before { content: @fa-var-file-pdf-o;     } }
                &.file-excel   { .file-icon:before { content: @fa-var-file-excel-o;   } }
                &.file-word    { .file-icon:before { content: @fa-var-file-word-o;    } }
                &.file-image   { .file-icon:before { content: @fa-var-file-image-o;   } }
                &.file-audio   { .file-icon:before { content: @fa-var-file-audio-o;   } }
                &.file-video   { .file-icon:before { content: @fa-var-file-video-o;   } }
                &.file-text    { .file-icon:before { content: @fa-var-file-text-o;    } }
            }

            > .row {
                > div {
                    margin-bottom: 1.428em;
                }
            }
        }
    }
}

//
// Locations Search
// --------------------------------------------------
.locations-search-page {
    .panel {
        .table {
            td {
                vertical-align: baseline;
            }
        }
    }
}

//
// Contacts Search
// --------------------------------------------------
.contacts-search-page {
    .panel {
        .table {
            td {
                vertical-align: baseline;
            }
        }
    }
}

//
// Typeahead styles
// --------------------------------------------------
.input-group .twitter-typeahead {
    display: table-cell !important;
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
	margin-bottom: 0;
}

.twitter-typeahead .empty-message {
    padding: 10px;
    text-align: center;
    min-width: 300px;

    span {
        white-space: nowrap;
    }
}

.twitter-typeahead .qs-item {
    white-space: nowrap;
}

.tt-hint {
	display: block;
	width: 100%;
	height: 38px;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #999;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.tt-menu {
	min-width: 160px;
	margin-top: 2px;
	padding: 5px 0;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	background-clip: padding-box;

}

.tt-suggestion {
	display: block;
	padding: 3px 20px;
}

.tt-suggestion:hover, .tt-suggestion.tt-cursor {
	color: #fff;
	background-color: #428bca;
}
